import { client } from '../client';

const PATH = 'registroasistencia';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  DELETE: codigoRegistroAsistencia =>
    `${PATH}/delete/${codigoRegistroAsistencia}`,
  GET_BY_GRUPO: (codigoGrupo, fechaInicial, fechaFinal) =>
    `${PATH}/grupo/${codigoGrupo}/fechainicial/${fechaInicial}/fechafinal/${fechaFinal}`,
  GET_BY_ESTUDIANTE: (codigoEstudiante, fechaInicial, fechaFinal) =>
    `${PATH}/estudiante/${codigoEstudiante}/fechainicial/${fechaInicial}/fechafinal/${fechaFinal}`,
  GET_RESUM_ASISTENCIA_GRUPO: `${PATH}/resum/grupo`,
  GET_ASISTENCIA_PROFESOR_FECHA: `${PATH}/profesor/fecha`,

};

const RegistroAsistenciaAPI = {
  async insertarRegistrosAsistencia(
    registrosAsistencia = [
      {
        codigoRegistroAsistencia: undefined,
        codigoGrupo: undefined,
        codigoEstudiante: undefined,
        codigoProfesor: undefined,
        fechaAsistencia: undefined,
        usuario: undefined
      },
    ],
  ) {
    return client.requestPost(ENDPOINTS.SAVE, registrosAsistencia);
  },

  async deleteRegistroAsistencia(codigoRegistroAsistencia) {
    return client.requestDelete(ENDPOINTS.DELETE(codigoRegistroAsistencia));
  },

  async getRegistroAsistenciaByGrupo(codigoGrupo, fechaInicial, fechaFinal) {
    return client.requestGet(ENDPOINTS.GET_BY_GRUPO(codigoGrupo, fechaInicial, fechaFinal));
  },

  async getRegistroAsistenciaByEsudiante(codigoEstudiante, fechaInicial, fechaFinal) {
    return client.requestGet(ENDPOINTS.GET_BY_ESTUDIANTE(codigoEstudiante, fechaInicial, fechaFinal));
  },

  async getResumAsistencia(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_RESUM_ASISTENCIA_GRUPO, params);
  },

  async getRegistroProfesorFecha(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ASISTENCIA_PROFESOR_FECHA, params);
  },
};

export default RegistroAsistenciaAPI;
