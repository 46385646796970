import { client } from '../client';

const PATH = 'estudiante';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  UPDATE: `${PATH}/update`,
  GET_BY_ID: `${PATH}/id`,
  GET_ALL: `${PATH}/all`,
  GET_ALL_FILTER: `${PATH}/all/filter`,
  GET_ALL_INSCRIPCION: `${PATH}/all/inscripcion`,
  GET_ALL_INSCRIPCION_FILTER:  `${PATH}/all/inscripcion/filter`,
  GET_ALL_CRUD: `${PATH}/allcrud`,
  GET_ALL_CRUD_FILTER: `${PATH}/allcrud/filter`,
  GET_EXISTS: `${PATH}/existeestudiante`,
  GET_ESTUDIANTE_ATRASO_GRUPO: `${PATH}/atrasofactura`,
  GET_ESTUDIANTE_ATRASO_SUCURSAL: `${PATH}/atrasofactura/sucursal`,
};

const EstudianteAPI = {
  async insertarEstudiante(
    estudiante = {
      activo: undefined,
      entidad: {
        nombre: undefined,
        apellido: undefined,
        fechaNacimiento: undefined,
        sexo: undefined,
        fechaRegistro: undefined,
        email: undefined,
        telefono: undefined,
        contactoPago: undefined,
        activo: undefined
      }
    }
  ) {
    return client.requestPost(ENDPOINTS.SAVE, estudiante);
  },

  async updateEstudiante(
    estudiante = {
      activo: undefined,
      codigoEstudiante: undefined,
      entidad: {
        codigoEntidad: undefined,
        nombre: undefined,
        apellido: undefined,
        fechaNacimiento: undefined,
        sexo: undefined,
        fechaRegistro: undefined,
        email: undefined,
        telefono: undefined,
        contactoPago: undefined,
        activo: undefined
      }
    }
  ) {
    return client.requestPut(ENDPOINTS.UPDATE, estudiante);
  },

  async getEstudianteById(params={}) {
    return client.requestWithParams(ENDPOINTS.GET_BY_ID, params);
  },

  async getAllEstudiantes(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL, params);
  },

  async getAllEstudiantesInscripcion(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_INSCRIPCION, params);
  },

  async getAllEstInsFilter(params={codigoSucursal, codigoGrupo, filtro, page, size, sortField, sortOrderAsc}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_INSCRIPCION_FILTER, params);
  },

  async getAllEstudiantesCrud(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_CRUD, params);
  },

  async getAllEstCrudFilter(params={codigoSucursal, filtro, page, size, sortField, sortOrderAsc}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL_CRUD_FILTER, params);
  },

  async existeEstudiante(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_EXISTS, params);
  },

  async getAllPagined(params={page, size, filtro, codigoSucursal}) {
    return client.requestWithParams(ENDPOINTS.GET_ALL, params);
  },

  async getAllFiltered(params={filtro, page, size, sortField, sortOrderAsc, codigoSucursal}){
    return client.requestWithParams(ENDPOINTS.GET_ALL_FILTER, params)
  },

  async getAllEstudianteAtrasoGrupo(params = {}) {
    return client.requestWithParams(ENDPOINTS.GET_ESTUDIANTE_ATRASO_GRUPO, params);
  },

  async getAllEstudianteAtrasoSucursal(params={codigoSucursal, filtro, page, size, sortField, sortOrderAsc}) {
    return client.requestWithParams(ENDPOINTS.GET_ESTUDIANTE_ATRASO_SUCURSAL, params);
  },
  
};

export default EstudianteAPI;
