export const useAppAccess = (accesos) => {

    const hasAccess = (codigoAcceso) => {
        if (accesos == undefined || accesos == null) {
            return false;
        }
        let index = accesos.findIndex(acceso => (acceso.codigoAcceso === codigoAcceso && acceso?.activo === true));
        return index >= 0;
    }

    const hasAccessToRoute = (ruta) => {

        if (accesos == undefined || accesos == null) {
            return false;
        }

        let index = accesos.findIndex(acceso => (acceso?.acceso?.ruta == ruta && acceso?.activo === true));
        return index >= 0;
    }

    const acceso = {
        get creacionUsuario() {
            return hasAccess(1);
        },
        get otorgaraccesoUsuario() {
            return hasAccess(2);
        },
        get cambiarContrasenaUsuario() {
            return hasAccess(3);
        },
        get cambiarContrasenaUsuarioOtro() {
            return hasAccess(4);
        },
        get periodoCreacion() {
            return hasAccess(5);
        },
        get subperiodoCreacion() {
            return hasAccess(6);
        },

        get grupoCreacion() {
            return hasAccess(7);
        },
        get profesorCreacion() {
            return hasAccess(8);
        },

        get estudianteCreacion() {
            return hasAccess(9);
        },
        get inscripcion() {
            return hasAccess(10);
        },
        get cobroFactura() {
            return hasAccess(11);
        },
        get reporteFacturaVencida() {
            return hasAccess(12);
        },

        get reporteInscripcionEstudianteGrupo() {
            return hasAccess(13);
        },

        get reporteAsistenciaNota() {
            return hasAccess(14);
        },

        get reporteAsistenciaGrupo() {
            return hasAccess(15);
        },

        get mensajeatraso() {
            return hasAccess(16);
        },
        get historialCobroEstudiante() {
            return hasAccess(17);
        },
        get ingresoResumenAnual() {
            return hasAccess(18);
        },
        get historialCobroFactura() {
            return hasAccess(19);
        },
        get estudianteAtrasoFactura() {
            return hasAccess(20);
        },
        get resumenAsistenciaGrupo() {
            return hasAccess(21);
        },
        get usuarioAsignarSucursal() {
            return hasAccess(23);
        },

        get detalleHorarioGrupo() {
            return hasAccess(24);
        },

        get transferenciaGrupo() {
            return hasAccess(25);
        },

        get aplicarReversionPago() {
            return hasAccess(26);
        },

        get resumenCobroFecha() {
            return hasAccess(27);
        },

        get compania() {
            return hasAccess(28);
        },

        get sucursalCompania() {
            return hasAccess(29);
        },
        get realizarCobroFactura() {
            return hasAccess(30);
        },
        get generarNuevaFactura() {
            return hasAccess(31);
        },

        get suplidor() {
            return hasAccess(32);
        },

        get articulo() {
            return hasAccess(33);
        },

        get evento() {
            return hasAccess(34);
        },

        get servicio() {
            return hasAccess(36);
        },

        get nuevaOrdenCompra() {
            return hasAccess(37);
        },

        get generarCuota(){
            return hasAccess(38);
        },

        get estatusGrafico(){
            return hasAccess(39);
        },

        get anularFacturaEstudiante(){
            return hasAccess(40);
        },

        get descuento(){
            return hasAccess(41);
        },

        get existenciaArticulo(){
            return hasAccess(42);
        },

        get transferenciaArticulo(){
            return hasAccess(43);
        },
        get moreInfoStudent(){
            // TODO: Este al parece no se usa
            return hasAccess(44);
        },

        get ingresoDiarioCaja(){
            return hasAccess(45);
        },

        get becas(){
            return hasAccess(46);
        },
        get otorgarBecas(){
            return hasAccess(47);
        },
        get categoriaArticulo() {
            return hasAccess(48);
        },
        get cancelarInscripcion() {
            return hasAccess(49);
        },
        get estudianteAtrasoSucursal() {
            return hasAccess(50);
        },

        tabEstudiante:{
            get documentos(){
                return hasAccess(51);
            },
            get asistencia(){
                return hasAccess(52);
            },
            get parentesco(){
                return hasAccess(53);
            },
            get direccion(){
                return hasAccess(54);
            },
            get pagos(){
                return hasAccess(55);
            },
            get cuotasPendientes(){
                return hasAccess(56);
            },
        },

        get reportEstudianteBecado() {
            return hasAccess(57);
        },

        get reportSeguimientoProfesor() {
            return hasAccess(58);
        },

        get responsableEstudiante() {
            return hasAccess(59);
        }

    }

    return { acceso, hasAccessToRoute }
}