import {client} from '../client';

const PATH = 'profesor';

const ENDPOINTS = {
  SAVE: `${PATH}/save`,
  UPDATE: `${PATH}/update`,
  GET_BY_ID: codigoProfesor => `${PATH}/id/${codigoProfesor}`,
  GET_ALL: `${PATH}/all`,
  GET_ALL_ACTIVE: `${PATH}/all/active`,
  GET_REPORT_ASISTENCIA: `${PATH}/reportasistencia`
};

const ProfesorAPI = {
  async insertarProfesor(
    profesor = {
        nuevoUsuarioDTO: {
          usuario: {
            usuario: undefined,
            password: undefined,
            profilePicture: undefined,
            fechaRegistro: undefined,
            tipoUsuario: undefined,
            activo: undefined
          },
          entidad: {
            nombre: undefined,
            apellido: undefined,
            fechaNacimiento: undefined,
            sexo: undefined,
            fechaRegistro: undefined,
            email: undefined,
            telefono: undefined,
            contactoPago: undefined,
            activo: undefined
          }, 
          sucursalUsuario:{
            codigoSucursal:undefined
          }
        },
        activo: undefined
      }
  ) {
    return client.requestPost(ENDPOINTS.SAVE, profesor);
  },

  async updateProfesor(
    profesor = {
        nuevoUsuarioDTO: {
          usuario: {
            usuario: undefined,
            password: undefined,
            profilePicture: undefined,
            fechaRegistro: undefined,
            tipoUsuario: undefined,
            activo: undefined
          },
          entidad: {
            nombre: undefined,
            apellido: undefined,
            fechaNacimiento: undefined,
            sexo: undefined,
            fechaRegistro: undefined,
            email: undefined,
            telefono: undefined,
            contactoPago: undefined,
            activo: undefined
          }
        },
        activo: undefined
      }
  ) {
    return client.requestPut(ENDPOINTS.UPDATE, profesor);
  },

  async getProfesorById(codigoProfesor) {
    return client.requestGet(ENDPOINTS.GET_BY_ID(codigoProfesor));
  },

  async getAllProfesores(params={}){
    return client.requestWithParams(ENDPOINTS.GET_ALL, params);
  },

  async getAllProfesoresActive(params={}){
    return client.requestWithParams(ENDPOINTS.GET_ALL_ACTIVE, params);
  },

  async getReportAsistencia(params={}){
    return client.requestWithParams(ENDPOINTS.GET_REPORT_ASISTENCIA, params);
  }
};

export default ProfesorAPI;
